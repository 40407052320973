import { Box, Radio, Typography, useTheme } from "@mui/material";
import {
    usePropertyCreation,
    type CurrentStep,
} from "services/property-creation-context";

interface PropertyMenuStepProps {
    selected: boolean;
    label: string;
    handleClick: (currentStep: CurrentStep) => void;
    idx: CurrentStep;
}

export const PropertyMenuStep = ({
    label,
    handleClick,
    selected,
    idx,
}: PropertyMenuStepProps) => {
    const { palette, customColors, customFontSizes } = useTheme();
    const { currentStep, highestAllowedStep } = usePropertyCreation();
    const isCurrent = idx === currentStep;
    console.log(
        `currentIdx ${idx} < highestAllowedIdx ${highestAllowedStep}`,
        idx < highestAllowedStep,
    );
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                backgroundColor: isCurrent ? customColors.colorHover : "#fff",
                alignItems: "center",
                paddingBlock: "1rem",
                flex: "auto",
                "&:hover": {
                    backgroundColor: customColors.colorHover,
                },
                borderBottom:
                    idx < highestAllowedStep
                        ? "4px solid green"
                        : idx === 10 && isCurrent
                        ? "4px solid green"
                        : "4px solid #cecece",
            }}
            onClick={() => {
                handleClick(idx);
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingInline: ".5rem",
                }}
            >
                <Typography
                    fontSize={customFontSizes.small}
                    sx={{
                        textDecoration: isCurrent ? "underline" : null,
                        fontWeight: isCurrent ? "bold" : "normal",
                        color: isCurrent
                            ? palette.primary.main
                            : palette.text.primary,
                    }}
                >
                    {label}
                </Typography>
            </Box>
        </Box>
    );
};
