import { Box, useTheme } from "@mui/material";
import { CheckboxInput, TwoColumnForm } from "components";
import { propertyFormStyles } from "components/styles";
import { useState } from "react";
import { getLocalizedLabels } from "services";
import { usePropertyCreation } from "services/property-creation-context";
import { LABELS } from "../../constants";

import { ValidationError } from "components/properties/ValidationError";
import { PackageCard } from "./PackageCard";
import { StepTwelveLabel } from "./StepTwelveLabel";

export const StepTwelveForm = () => {
    const { customColors } = useTheme();
    const labels = getLocalizedLabels(LABELS);
    const {
        coupons,
        packages,
        property,
        handleTextChange,
        setProperty,
        agreedToTerms,
        setAgreedToTerms,
        validationErrors,
        setValidationErrors,
    } = usePropertyCreation();
    const { transaction_type } = property;
    const availablePackages = packages.filter(
        (p) => p.sale_type === transaction_type,
    );
    const [selectedPackageId, setSelectedPackageId] = useState<number>();

    const selectPackage = (packageId: number) => {
        setSelectedPackageId(packageId);
        setValidationErrors((prev) =>
            prev.filter((err) => err !== "package_selected"),
        );
        setProperty((prev) => ({ ...prev, package_selected: packageId }));
    };

    const currentPackage = availablePackages.filter(
        (p) => p.id === selectedPackageId,
    )[0];

    return (
        <Box
            sx={{
                ...propertyFormStyles.centeredContainer,
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    // gap: ".5rem",
                }}
            >
                {availablePackages &&
                    availablePackages.map((packageData) => (
                        <PackageCard
                            key={packageData.id}
                            packageData={packageData}
                            isSelected={
                                selectedPackageId
                                    ? selectedPackageId === packageData.id
                                    : property.package_selected ===
                                      packageData.id
                            }
                            handleClick={selectPackage}
                        />
                    ))}
            </Box>
            {validationErrors.includes("package_selected") && (
                <Box
                    sx={{
                        border: "1px solid rgb(128,0,0,1)",
                        padding: "8px",
                        borderRadius: "4px",
                        marginBottom: "2rem",
                    }}
                >
                    <ValidationError message={"Bitte wählen Sie ein Paket."} />
                </Box>
            )}

            <TwoColumnForm
                leftSide={
                    <Box>
                        {currentPackage && (
                            <>
                                <StepTwelveLabel
                                    value={"Ihr Paket: " + currentPackage.name}
                                />
                                <StepTwelveLabel
                                    value={`Gesamtsumme: ${currentPackage.price} €`}
                                    additional_info="(Coupons und Zahlungsinformation geben Sie
                                    nachfolgend ein.)"
                                />
                            </>
                        )}

                        <CheckboxInput
                            name="agbs"
                            label="Ich bestätige die Nutzungsbedingungen gelesen zu haben und bin mit diesen einverstanden."
                            checked={agreedToTerms}
                            handleChange={() => {
                                setAgreedToTerms((prev) => !prev);
                            }}
                        />
                    </Box>
                }
                rightSide={<></>}
            />
        </Box>
    );
};
