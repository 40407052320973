import {
    StepTwoForm,
    StepOneForm,
    StepFourForm,
    StepThreeForm,
    StepFiveForm,
    StepSixForm,
    StepSevenForm,
    StepEightForm,
    StepNineForm,
    StepTenForm,
    StepElevenForm,
    StepTwelveForm,
} from "components";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { PropertyCreationStepsMenu } from "components/properties/PropertyCreationStepsMenu";
import { propertyFormStyles } from "components/styles";
import { defaultProperty } from "defaults/property.default";
import { useNavigate } from "react-router-dom";
import {
    usePropertyCreation,
    type CurrentStep as Step,
} from "services/property-creation-context";
import {
    BASE_AWS_S3_URL,
    createPropertyAPI,
    updatePropertyAPI,
} from "services";

import { uploadImagesToAWS } from "services/img-utils";
import { usePropertyEdit } from "services/property-edit-context";
import { PropertyEditStepsMenu } from "components/properties/PropertyEditStepsMenu";
import { useState } from "react";
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ImageEditForm } from "components/properties/forms/ImageEditForm";
import { Property } from "@interfaces";

const PropertyEditPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState<string>("");

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const {
        currentStep,
        property,
        previewImages,
        setCurrentStep,
        setPreviewImages,
        setProperty,
        loadingImages,
    } = usePropertyEdit();

    const advance = () => {
        if (currentStep === 8) {
        }
        if (currentStep < 10) setCurrentStep((currentStep + 1) as Step);
    };

    const goBack = () => {
        if (currentStep > 0) setCurrentStep((currentStep - 1) as Step);
    };

    const submitPropertyEditForm = async () => {
        const loggedInUser = localStorage.getItem("user");
        setLoading((_) => true);
        await uploadImagesToAWS(previewImages, property);

        if (!property.id) return;
        const res = await updatePropertyAPI(property.id, {
            ...property,
            user: Number(loggedInUser),
        });
        if (!axios.isAxiosError(res)) {
            setSuccessMessage("Die Änderungen wurden erfolgreich gespeichert.");
            setOpen(true);
            setPreviewImages([]);
            if (currentStep === 8) setCurrentStep(9);
        }

        // navigate("/property/success");
        setLoading((_) => false);
    };

    const closeAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Steps Menu */}
            {currentStep < 12 && (
                <PropertyEditStepsMenu
                    property={defaultProperty}
                    currentStep={currentStep}
                />
            )}
            {/* Forms */}
            {currentStep === 0 && <StepOneForm />}
            {currentStep === 1 && <StepTwoForm />}
            {currentStep === 2 && <StepThreeForm />}
            {currentStep === 3 && <StepFourForm />}
            {currentStep === 4 && <StepFiveForm />}
            {currentStep === 5 && <StepSixForm />}
            {currentStep === 6 && <StepSevenForm />}
            {currentStep === 7 && <StepEightForm />}
            {currentStep === 8 && <ImageEditForm />}
            {currentStep === 9 && <StepTenForm />}
            {/* {currentStep === 10 && <StepElevenForm />} */}
            {/* {currentStep === 11 && <StepTwelveForm />} */}

            {/* Navigation */}
            {loadingImages ? (
                <Box
                    sx={{
                        ...propertyFormStyles.navigationRow,
                        marginBlock: "1rem",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={propertyFormStyles.navigationRow}>
                    <Button
                        disabled={currentStep === 0 || loading}
                        sx={{ margin: "1rem", minWidth: "100px" }}
                        onClick={goBack}
                    >
                        Zurück
                    </Button>
                    <Button
                        disabled={currentStep === 0 || loading}
                        sx={{ margin: "1rem", minWidth: "100px" }}
                        onClick={submitPropertyEditForm}
                    >
                        {loading && <CircularProgress />}
                        Speichern
                    </Button>
                    {currentStep < 9 && (
                        <Button
                            disabled={loading || currentStep === 9}
                            sx={{ margin: "1rem", minWidth: "100px" }}
                            onClick={advance}
                        >
                            Weiter
                        </Button>
                    )}
                </Box>
            )}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={closeAction}
            >
                <Alert severity="success" onClose={handleClose}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PropertyEditPage;
