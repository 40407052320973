import {
    Box,
    Button,
    CircularProgress,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { formatDate, formatDateTime } from "services";
import { FaDownload, FaEye } from "react-icons/fa";
import {
    toggleCancelSubscriptionAPI,
    togglePauseSubscriptionAPI,
} from "services/payments";
import { useEffect, useState } from "react";
import { Subscription } from "@interfaces";

const formatCurrency = (amount: number): string => {
    return amount.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
    });
};

export const SubscriptionDetailContent = ({
    subscription: fromParentSubscription,
    isCurrentSubscriptionPeriod,
}: {
    subscription: Subscription;
    isCurrentSubscriptionPeriod: boolean;
}) => {
    const [subscription, setSubscription] = useState(fromParentSubscription);
    const [loading, setLoading] = useState<boolean>(false);
    const { customColors } = useTheme();
    const startDate = subscription.subscription_summary.current_period_start;
    const endDate = subscription.subscription_summary.current_period_end;

    const subscriptionIsCanceled =
        subscription.subscription_summary.status === "canceled";
    const subscriptionIsPaused =
        subscription.subscription_summary.pause_collection;
    const subscriptionWillBeCanceled =
        subscription.subscription_summary.cancel_at_period_end;
    const subscriptionIsPausedOrCanceled =
        subscriptionIsCanceled ||
        subscriptionIsPaused ||
        subscriptionWillBeCanceled;

    const intervalMapSingular = {
        day: "Tag",
        week: "Woche",
        month: "Monat",
        year: "Jahr",
    };
    const intervalMapPlural = {
        day: "Tage",
        week: "Wochen",
        month: "Monate",
        year: "Jahre",
    };

    const parseInterval = (): string => {
        const interval = subscription.subscription_summary.interval;
        const intervalCount = subscription.subscription_summary.interval_count;
        if (intervalCount === 1) {
            return `1 ${intervalMapSingular[interval]}`;
        }
        return `${intervalCount} ${intervalMapPlural[interval]}`;
    };

    const getCancelButtonLabel = () => {
        if (subscriptionIsCanceled) {
            return "Gekündigt";
        }
        if (subscription.subscription_summary.cancel_at_period_end) {
            return "Abo fortsetzen";
        }

        return "Abo kündigen";
    };

    const getTooltipForAboButton = () => {
        if (subscriptionIsCanceled) {
            return "Abo wurde bereits gekündigt";
        }
        if (subscriptionWillBeCanceled)
            return "Abo wird am Ende des aktuellen Abrechnungszeitraums gekündigt.\nKlicken Sie auf 'Abo fortsetzen', um das Abo zu reaktivieren.\nEs fallen keine weiteren Kosten an.";
        if (!subscriptionIsCanceled && !subscriptionWillBeCanceled)
            return "Wenn Sie ihr Abo kündigen, bleibt ihr Inserat bis zum Ende der aktuellen Laufzeit auf allen Portalen online. Sie können ihr Abo jederzeit reaktivieren.";
    };

    const togglePauseSubscription = async (subscriptionId: string) => {
        try {
            setLoading(true);
            const res = await togglePauseSubscriptionAPI(subscriptionId);
            if (res) {
                setSubscription({
                    ...subscription,
                    subscription_summary: {
                        ...subscription.subscription_summary,
                        pause_collection: res.paused,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const toggleCancelSubscription = async (subscriptionId: string) => {
        try {
            setLoading(true);
            const res = await toggleCancelSubscriptionAPI(subscriptionId);
            if (res) {
                setSubscription({
                    ...subscription,
                    subscription_summary: {
                        ...subscription.subscription_summary,
                        status: res.status,
                        cancel_at_period_end: res.cancel_at_period_end,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
                flexWrap: "wrap-reverse",
                borderBottom: "2px solid #e0e0e0",
                marginBlock: ".2rem",
                padding: ".25rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    gap: { xs: "1rem", sm: "2rem" },
                    minWidth: { xs: "100%", sm: "80%", md: "50%" },
                    flexWrap: "wrap",
                }}
            >
                <Box sx={{ minWidth: { xs: "100%", sm: "200px" } }}>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                            fontWeight: "bold",
                        }}
                    >
                        {isCurrentSubscriptionPeriod
                            ? "Aktuelle Laufzeit"
                            : "Laufzeit abgeschlossen"}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                        }}
                    >
                        {formatDate(startDate, true)} -{" "}
                        {formatDate(endDate, true)}
                    </Typography>
                </Box>

                <Box sx={{ minWidth: { xs: "100%", sm: "0" } }}>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                            fontWeight: "bold",
                        }}
                    >
                        Letzte Zahlung
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                        }}
                    >
                        {formatDateTime(subscription.created_on, true)}
                    </Typography>
                </Box>
                <Box sx={{ minWidth: { xs: "100%", sm: "0" } }}>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                            fontWeight: "bold",
                        }}
                    >
                        Laufzeitdauer
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                        }}
                    >
                        {parseInterval()}
                    </Typography>
                </Box>

                {!subscriptionIsPausedOrCanceled && (
                    <Box
                        sx={{
                            minWidth: { xs: "100%", sm: "0" },
                            visibility: isCurrentSubscriptionPeriod
                                ? "visible"
                                : "hidden",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: ".8rem",
                                marginBlock: ".2rem",
                                fontWeight: "bold",
                            }}
                        >
                            Nächste Zahlung
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: ".8rem",
                                marginBlock: ".2rem",
                            }}
                        >
                            {formatDate(endDate, true)}
                        </Typography>
                    </Box>
                )}

                <Box sx={{ minWidth: { xs: "100%", sm: "0" } }}>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                            fontWeight: "bold",
                        }}
                    >
                        Rechnung
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            textDecoration: "underline",
                            "&:hover": {
                                cursor: "pointer",
                                color: customColors.colorPrimary,
                            },
                        }}
                        onClick={() =>
                            window.open(subscription.invoice_pdf_url)
                        }
                    >
                        Download <FaDownload style={{ marginLeft: ".2rem" }} />
                    </Typography>
                </Box>
                <Box sx={{ minWidth: { xs: "100%", sm: "0" } }}>
                    <Typography
                        sx={{
                            fontSize: ".8rem",
                            marginBlock: ".2rem",
                            fontWeight: "bold",
                        }}
                    >
                        &nbsp;
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ".8rem",

                            textDecoration: "underline",
                            "&:hover": {
                                cursor: "pointer",
                                color: customColors.colorPrimary,
                            },
                        }}
                        onClick={() =>
                            window.open(
                                subscription.hosted_invoice_url,
                                "_blank",
                            )
                        }
                    >
                        Details & Beleg{" "}
                        <FaEye style={{ marginLeft: ".2rem" }} />
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
                {!(loading && isCurrentSubscriptionPeriod) ? (
                    <>
                        <Box
                            sx={{
                                visibility: isCurrentSubscriptionPeriod
                                    ? "visible"
                                    : "hidden",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: ".8rem",
                                    marginBlock: ".2rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Aktionen
                            </Typography>
                            <Button
                                sx={{
                                    maxHeight: "20px",
                                    textTransform: "none",
                                    mr: ".25rem",
                                }}
                                disabled={
                                    subscription.subscription_summary.status ===
                                        "canceled" ||
                                    subscription.subscription_summary
                                        .cancel_at_period_end
                                }
                                onClick={() =>
                                    togglePauseSubscription(
                                        subscription.subscription_summary.id,
                                    )
                                }
                            >
                                {!subscription.subscription_summary
                                    .pause_collection
                                    ? "Pausieren"
                                    : "Fortsetzen"}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                visibility: isCurrentSubscriptionPeriod
                                    ? "visible"
                                    : "hidden",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: ".8rem",
                                    marginBlock: ".2rem",
                                    fontWeight: "bold",
                                }}
                            >
                                &nbsp;
                            </Typography>
                            <Tooltip title={getTooltipForAboButton()}>
                                <Button
                                    disabled={subscriptionIsCanceled}
                                    sx={{
                                        maxHeight: "20px",
                                        textTransform: "none",
                                        backgroundColor: "maroon",
                                        borderColor: "maroon",
                                        "&:hover": {
                                            color: "maroon",
                                        },
                                    }}
                                    onClick={() =>
                                        toggleCancelSubscription(
                                            subscription.subscription_summary
                                                .id,
                                        )
                                    }
                                >
                                    {getCancelButtonLabel()}
                                </Button>
                            </Tooltip>
                        </Box>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </Box>
        </Box>
    );
};
