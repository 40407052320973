import { Property } from "@interfaces";
import { formatDate, IS_DEV, useApp } from "services";
import { usePropertyCreation } from "./property-creation-context";
import { Box } from "@mui/system";
import { Button, CircularProgress, LinearProgress } from "@mui/material";
import {
    AvailibilityOptions,
    FlatTypeOptions,
    StateOfDevelopment,
} from "enums";
import { defaultProperty } from "defaults/property.default";
import { useState } from "react";
import { StepTwelveForm } from "@components";
import { StepTwelveLabel } from "components/properties/forms/Step12/StepTwelveLabel";
export const RandomTestPropertyButton = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useApp();
    const { property, setProperty, setCurrentStep } = usePropertyCreation();
    // const randomInt = () => Math.random() * 1000;
    const randomBoolean = () => Math.random() >= 0.5;
    // Random integer between 1 and 10 (inclusive)
    const generateUserName = (): string => {
        if (user?.first_name && user?.last_name)
            return `${user.first_name} ${user.last_name}`;
        return "Random Test User";
    };

    const generateRandomText = (length: number): string => {
        let result = "";
        const characters =
            "ABCDEFGHIJKLMN OPQRSTUVWXYZabcdef ghijklmnopqrstuvw xyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength),
            );
        }
        return result;
    };

    const randomInt1To10 = () => Number((Math.random() * 10 + 1).toFixed(0));

    const randomInt100To300 = () =>
        Number((Math.random() * 201 + 100).toFixed(0));

    const randomInt100To500 = () =>
        Number((Math.random() * 401 + 100).toFixed(0));

    const randomIntGreaterThan10000 = () =>
        Number((Math.random() * 990001 + 10000).toFixed(0));

    const generateRandomTestProperty = () => {
        setLoading(true);
        setProperty((prev) => ({
            ad_optimization: randomBoolean(),
            additional_price: randomInt100To300(),
            agent_handover_requested: randomBoolean(),
            archived: false,
            balcony: randomBoolean(),
            barrier_free: randomBoolean(),
            base_rent: randomInt100To500(),
            basement: randomBoolean(),
            bathroom_count: randomInt1To10(),
            bedroom_count: randomInt1To10(),
            by_agreement: randomBoolean(),
            central_heating: randomBoolean(),
            chimney: randomBoolean(),
            city: "Tuebingen",
            country: "Deutschland",
            created_on: "",
            date_of_creation: "",
            deed: randomBoolean(),
            deposit: randomInt100To500(),
            description:
                "Zufällige Beschreibung für Testzwecke " +
                generateRandomText(randomInt100To500()),
            description_of_equipment: generateRandomText(randomInt100To300()),
            description_of_location: generateRandomText(randomInt100To300()),
            district_heating: randomBoolean(),
            electric: randomBoolean(),
            elevator: randomBoolean(),
            email: user?.email || "test@example.com",
            energy_card_type: "",
            energy_certificate: "Ja, Liegt vor",
            energy_consumption: randomInt100To500(),
            // energy_data: null,
            energy_date_of_creation: "",
            energy_for_hot_water_included: randomBoolean(),
            energy_need: randomInt100To300(),
            energy_year_of_construction: null,
            estate_sync_contact_id: null,
            estate_sync_property_id: null,
            export_property: randomBoolean(),
            fitted_kitchen: randomBoolean(),
            flat_type: FlatTypeOptions.Apartment,
            floor: randomInt100To500(),
            floor_count: randomInt1To10(),
            floor_plan: randomBoolean(),
            free_from_date: "",
            free_from: AvailibilityOptions.Sofort,
            // free_from_date: formatDate(new Date().toISOString()),
            full_bath: randomBoolean(),
            garage_count: randomInt1To10(),
            garage_price: randomInt100To500(),
            garden: randomBoolean(),
            gas: randomBoolean(),
            guest_toilet: randomBoolean(),
            heating_cost: randomInt100To500(),
            heating_cost_included: randomBoolean(),
            hide_address: randomBoolean(),
            hide_email: true,
            hide_name: randomBoolean(),
            hide_phone: randomBoolean(),
            house_number: "23",
            house_type: "Doppelhaushälfte",
            id: null,
            images: [],
            immoscout_listing_id: "",
            immowelt_listing_id: "",
            in_need_of_renovation: randomBoolean(),

            is_active: false,
            lat: 52.123456,
            living_space: randomInt100To300(),
            lng: 13.987654,
            message_count: null,
            name: generateUserName(),
            new_building: randomBoolean(),
            oil: randomBoolean(),
            old_building: randomBoolean(),
            open_fireplace: randomBoolean(),
            package_end_date: null,
            patio: randomBoolean(),
            pellets: randomBoolean(),
            phone: "0123456789",
            plan: null,
            plan_duration: null,
            plot_area: randomInt100To500(),
            post_code: 72074,
            price: randomIntGreaterThan10000(),
            professional_contract_review: randomBoolean(),
            professional_photo: randomBoolean(),
            property_type: prev.property_type,
            refurbished: randomBoolean(),
            renovated: randomBoolean(),
            rooftop_terrace: randomBoolean(),
            room_count: randomInt1To10(),
            sauna: randomBoolean(),
            shower_room: randomBoolean(),
            solar: randomBoolean(),
            state_of_development: StateOfDevelopment.Teilerschlossen,
            storey_heating: randomBoolean(),
            street: "Provenceweg",
            swimming_pool: randomBoolean(),
            tiled_stove: randomBoolean(),
            title: "Testimmobilie " + randomIntGreaterThan10000(),
            total_rental_cost: randomInt100To500(),
            transaction_type: prev.transaction_type,
            underfloor_heating: randomBoolean(),
            updated_on: "",
            user: user?.id || 99999999999999999999999,
            winter_garden: randomBoolean(),
            year_of_construction: 1000 + randomInt100To500(),
            coupon_code: "",
            invoice_amount: "",
            package_selected: null,
        }));
        setTimeout(() => {
            setLoading((prev) => false);
            setCurrentStep(9);
        }, 1500);
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: { xs: "1rem", sm: "0rem" },
            }}
        >
            <StepTwelveLabel value={"Entwicklungs- und Testhilfen"} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                    width: "100%",
                }}
            >
                {IS_DEV &&
                    property.transaction_type &&
                    property.property_type && (
                        <Button
                            sx={{
                                textTransform: "none",
                                backgroundColor: "green",
                                border: "green",
                                alignSelf: " center",
                                flex: 1,
                                minWidth: "250px",
                            }}
                            onClick={generateRandomTestProperty}
                        >
                            {loading && (
                                <CircularProgress
                                    size={20}
                                    sx={{ ml: "1rem" }}
                                />
                            )}
                            {loading
                                ? "Generiere Testdaten ... "
                                : "Testimmobilie generieren"}
                        </Button>
                    )}
                {IS_DEV &&
                    property.transaction_type &&
                    property.property_type && (
                        <Button
                            sx={{
                                textTransform: "none",
                                backgroundColor: "maroon",
                                border: "green",
                                alignSelf: " center",
                                flex: 1,
                                minWidth: "250px",
                            }}
                            onClick={() => {
                                setProperty(defaultProperty);
                            }}
                        >
                            Formular zurücksetzen
                        </Button>
                    )}
            </Box>
        </Box>
    );
};
