import axios, { type AxiosError } from "axios";

import { BASE_API_URL } from "./constants";
import {
    type Coupon,
    type Enquiry,
    type Package,
    type Property,
    type PropertyCreateDto,
} from "@interfaces";
import { createPropertyDto } from "./apiUtils";
import { Message } from "interfaces/message.interface";
import exp from "constants";

const _getWithHeader = async <T,>(url: string): Promise<T | void> => {
    try {
        const { data } = await axios.get<T>(url, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error.response.data);
        throw error;
    }
};

const _postWithHeader = async <T,>(
    url: string,
    payload: T,
): Promise<T | AxiosError> => {
    try {
        const { data } = await axios.post<T>(url, payload, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error?.response?.data);
        return error as AxiosError;
    }
};

const _deleteWithHeader = async <T,>(url: string): Promise<T | AxiosError> => {
    try {
        const { data } = await axios.delete<T>(url, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error?.response?.data);
        return error as AxiosError;
    }
};

const _patchWithHeader = async <T,>(
    url: string,
    payload: PropertyCreateDto,
): Promise<T | AxiosError> => {
    try {
        const { data } = await axios.patch<T>(url, payload, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error?.response?.data);
        return error as AxiosError;
    }
};

export const createPaymentLinkAPI = async (): Promise<{
    url: string;
} | void> => {
    try {
        const url = `${BASE_API_URL}/payments/stripe/create-payment-link`;
        const paymentLink = await _getWithHeader<{ url: string }>(url);
        if (paymentLink) return paymentLink;
    } catch (error: any) {
        throw error;
    }
};

export const getSubscriptionDetailsAPI = async (): Promise<any> => {
    try {
        const url = `${BASE_API_URL}/payments/stripe/subscriptions`;
        const subscriptionDetails = await _getWithHeader(url);
        return subscriptionDetails;
    } catch (error: any) {
        throw error;
    }
};

export const togglePauseSubscriptionAPI = async (
    id: string,
): Promise<
    | {
          status: "active" | "paused" | "canceled";
          paused: boolean;
      }
    | undefined
> => {
    try {
        const url = `${BASE_API_URL}/payments/stripe/subscriptions/pause/${id}`;
        const subscriptionDetails = await _getWithHeader<{
            status: "canceled" | "active" | "paused";
            paused: boolean;
        }>(url);
        if (subscriptionDetails) return subscriptionDetails;
    } catch (error: any) {
        throw error;
    }
};

export const toggleCancelSubscriptionAPI = async (
    id: string,
): Promise<
    | {
          status: "active" | "paused" | "canceled";
          paused: boolean;
          cancel_at_period_end: boolean;
      }
    | undefined
> => {
    try {
        const url = `${BASE_API_URL}/payments/stripe/subscriptions/cancel/${id}`;
        const subscriptionDetails = await _getWithHeader<{
            status: "canceled" | "active" | "paused";
            paused: boolean;
            cancel_at_period_end: boolean;
        }>(url);
        if (subscriptionDetails) return subscriptionDetails;
    } catch (error: any) {
        throw error;
    }
};
