import { useEffect } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { ListManager } from "react-beautiful-dnd-grid";
import { imageUploaderStyles } from "./imageUploader.styles";
import { usePropertyCreation } from "services/property-creation-context";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import { IconButton, Typography, useTheme } from "@mui/material";
import { Property, type PreviewImage } from "@interfaces";
import { getPreviewUrls } from "services/img-utils";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const ImageDisplay = ({ imageUrl }: { imageUrl: string }) => {
    return (
        <div>
            <img src={imageUrl} alt="property-preview" />
        </div>
    );
};

export const PropertyImageList = ({
    property,
}: {
    property: Property;
}): JSX.Element => {
    const images = property.images.map((image) => (
        <ImageDisplay key={property.id} imageUrl={image.url} />
    ));
    return (
        <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
            {images}
        </Box>
    );
};
