import { Button } from "@mui/material";

export const EstateSyncAdminButton = ({
    is_active,
    handleClick,
    label,
}: {
    is_active: boolean;
    label: string;
    handleClick: () => {};
}) => {
    return (
        <Button
            onClick={handleClick}
            sx={{
                textTransform: "none",
                paddingInline: ".25rem",
                paddingBlock: 0,
                fontSize: "14px",
                width: "100px",
                backgroundColor: is_active ? "maroon" : "green",
                border: "2px transparent",
                "&:hover": {
                    border: "2px solid",
                    backgroundColor: "inherit",
                },
            }}
        >
            {label}
        </Button>
    );
};
