import {
    AdminProperty,
    EstateSyncProperty,
    Property,
    PropertyCreateDto,
} from "@interfaces";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Tooltip,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { margin } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import {
    deactivatePropertyAPI,
    deleteAllTestDataAPI,
    IS_DEV,
    searchPropertiesAsAdminAPI,
    updatePropertyAPI,
    useApp,
} from "services";
import { createPaymentLinkAPI } from "services/payments";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginPage from "./LoginPage";
import { Close } from "@mui/icons-material";
import { EstateSyncAdminButton } from "components/buttons/EstateSyncAdminButton";

const styles = {
    link: {
        marginBlock: ".2rem",
    },
    section: { marginBlock: "1rem" },

    row: { justifyContent: "flex-start", display: "flex", flex: 1 },
    column: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        minWidth: "220px",
    },
    text: {
        border: "1px solid black",
        flex: 1,
        padding: ".2rem",
        minHeight: "50px",
        minWidth: "220px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    textAlignRight: {
        border: "1px solid black",
        flex: 1,
        padding: ".2rem",
        minHeight: "40px",
        minWidth: "220px",
        justifyContent: "flex-end",
        display: "flex",
        alignItems: "center",
        paddingInline: "1rem",
    },
};

export const EstateSyncAdminPage = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<boolean | string>(false);
    const [loadingProperties, setLoadingProperties] = useState(false);
    const [properties, setProperties] = useState<AdminProperty[]>([]);
    const [idPendingUpdate, setIdPendingUpdate] = useState<number | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const { isAdmin } = useApp();

    const updateSearch = (e: React.ChangeEvent) => {
        const { value } = e.target as HTMLInputElement;
        const currentParams = searchParams;
        if (value) {
            currentParams.set("property_query", value);
        } else {
            currentParams.delete("property_query");
        }
        setSearchParams(currentParams);
    };

    const updateSearchParamsViaCheckbox = (e: React.ChangeEvent) => {
        const { name, checked } = e.target as HTMLInputElement;
        const currentParams = searchParams;
        if (checked) {
            currentParams.set(name, "true");
        } else {
            currentParams.delete(name);
        }
        setSearchParams(currentParams);
    };

    const refetchProperties = useCallback(async () => {
        try {
            setLoadingProperties(true);
            const parsedParams = new URLSearchParams(searchParams.toString());
            const data = await searchPropertiesAsAdminAPI(
                parsedParams.toString(),
            );
            if (data) setProperties(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingProperties(false);
        }
    }, []);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                setLoadingProperties(true);
                const parsedParams = new URLSearchParams(
                    searchParams.toString(),
                );
                const data = await searchPropertiesAsAdminAPI(
                    parsedParams.toString(),
                );
                if (data) setProperties(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingProperties(false);
            }
        };

        // const handler = setTimeout(() => fetchProperties(), 1200);
        fetchProperties();

        // return () => clearTimeout(handler);
    }, [searchParams, idPendingUpdate]);

    const parseProperty = (
        admin_property: AdminProperty,
    ): PropertyCreateDto => {
        return { ...admin_property, user: admin_property.user.id };
    };

    const togglePropertyActiveState = async (property: PropertyCreateDto) => {
        try {
            if (!property.id) return;
            setIdPendingUpdate(() => property.id);
            await updatePropertyAPI(property.id, {
                ...property,
                is_active: !property.is_active,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIdPendingUpdate(() => null);
        }
    };

    const setPropertyInactive = async (property: PropertyCreateDto) => {
        try {
            if (!property.id) return;
            setIdPendingUpdate(() => property.id);
            await updatePropertyAPI(property.id, {
                ...property,
                is_active: false,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIdPendingUpdate(() => null);
        }
    };

    return isAdmin || IS_DEV ? (
        <>
            <Box sx={{ display: "flex", gap: "1rem" }}>
                {" "}
                <TextField
                    value={searchParams.get("property_query") || ""}
                    onChange={updateSearch}
                    placeholder="Suche"
                    sx={{
                        backgroundColor: "#fff",
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            onChange={(e) => updateSearchParamsViaCheckbox(e)}
                            name="is_active"
                        />
                    }
                    label="Aktiv - Filter"
                />
                <IconButton onClick={() => refetchProperties()}>
                    <RefreshIcon />
                </IconButton>
            </Box>

            {loadingProperties ? (
                <CircularProgress />
            ) : (
                <Box sx={{ flexDirection: "column" }}>
                    <Box sx={styles.row}>
                        <Box sx={styles.section}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Anzeige
                                            </TableCell>
                                            <TableCell align="left">
                                                User
                                            </TableCell>
                                            <TableCell align="left">
                                                Estate Sync Id
                                            </TableCell>
                                            <TableCell align="left">
                                                Aktiv/Inaktiv
                                            </TableCell>
                                            <TableCell align="left">
                                                Immoscout Listing Id
                                            </TableCell>
                                            <TableCell align="left">
                                                Immowelt Listing Id
                                            </TableCell>
                                            <TableCell align="left">
                                                Aktionen
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {properties.map((property) => (
                                            <TableRow
                                                key={property.id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {property.title}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {property.user.email}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {
                                                        property.estate_sync_property_id
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    {property.is_active ? (
                                                        <CheckIcon />
                                                    ) : (
                                                        <CloseIcon />
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {property.immoscout_listing_id ? (
                                                        property.immoscout_listing_id
                                                    ) : (
                                                        <CloseIcon />
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {property.immowelt_listing_id ? (
                                                        property.immowelt_listing_id
                                                    ) : (
                                                        <CloseIcon />
                                                    )}
                                                </TableCell>
                                                {idPendingUpdate ===
                                                property.id ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <TableCell align="left">
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                gap: ".25rem",
                                                            }}
                                                        >
                                                            <EstateSyncAdminButton
                                                                is_active={
                                                                    property.is_active
                                                                }
                                                                handleClick={() =>
                                                                    togglePropertyActiveState(
                                                                        parseProperty(
                                                                            property,
                                                                        ),
                                                                    )
                                                                }
                                                                label={
                                                                    property.is_active
                                                                        ? "Deaktivieren"
                                                                        : "Aktivieren"
                                                                }
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    {IS_DEV && (
                        <>
                            <Button
                                onClick={async () => {
                                    try {
                                        setLoadingProperties(true);
                                        await deleteAllTestDataAPI();
                                        refetchProperties();
                                    } catch (error) {
                                        console.error(error);
                                    } finally {
                                        setLoadingProperties(false);
                                    }
                                }}
                            >
                                Testdaten löschen
                            </Button>
                            <Typography>
                                Es werden alle Testdaten gelöscht. Diese Aktion
                                kann nicht rückgängig gemacht werden.
                                (Ausgeschlossen ist der Paketkatalog)
                            </Typography>
                        </>
                    )}
                </Box>
            )}
        </>
    ) : (
        <LoginPage isAdminPage={true} />
    );
};
